<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="secondTitle">充值云淘豆</div>
      <div v-html="alipayHtml" ref="alipayWap"></div>
      <el-form ref="form" :model="form" v-loading="loading"  class="recharge">
        <el-form-item label="账户云淘豆">
          <span style="font-weight: 600; color: #ff6701; font-size: 18px;">{{ $util.numberFormat(UserBlance)  }}</span>
        </el-form-item>
        <el-form-item label="充值云淘豆">
          <el-input
            v-model="form.Balance"
            placeholder="请输入充值云淘豆"
            style="width: 400px"
            oninput="value=value.replace(/[^\d]/g,'');if(value>99900)value=99900"
            min="1"
            max="99900"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认云淘豆">
          <el-input
            v-model="form.BalanceAgain"
            placeholder="请输入确认云淘豆"
            style="width: 400px"
            oninput="value=value.replace(/[^\d]/g,'');if(value>99900)value=99900"
            min="1"
            max="99900"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-radio-group
            v-model="PaymentType"
            v-for="(item, index) in PaymentTypes"
            :key="index"
          >
            <el-radio  :label="item.Id" class="radioImg">
              <el-image :src="item.ImgPath"></el-image
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item style="padding-left: 80px">
          <el-button type="primary" @click="onSubmit">确认充值</el-button>
          <span class="tips">*请在30分钟内完成支付，以免订单取消</span>
        </el-form-item>
      </el-form>
      <div  >
        <div class="title" style="font-size: 18px">充值记录</div>
        <el-table :data="RechargeRecord" stripe style="width: 100%">
          <el-table-column prop="RechargeNumber" label="订单号" width="300"></el-table-column>
          <el-table-column prop="Amount" label="充值云淘豆" width="180">
          </el-table-column>
          <el-table-column prop="Type" label="充值方式"> </el-table-column>
          <el-table-column prop="Status" label="状态"></el-table-column>
          <el-table-column prop="Time" label="充值时间" width="180">
          </el-table-column>
        </el-table>
        <!-- 分页条 -->
        <el-pagination
          style="margin-top: 10px"
          v-if="RechargeRecord.length > 0"
          :pager-count="5"
          :page-sizes="Pagination.pageSizes"
          :page-size="Pagination.pageSize"
          :current-page="Pagination.pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="Pagination.totalSize"
          @current-change="pageChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
      <!-- 支付弹窗 -->
      <wxrecjarge
        :id="drErWeiMa.id"
        :url="drErWeiMa.url"
        :xvisible.sync="xvisible"
      >
      </wxrecjarge>
    </menu-wrapper>
  </div>
</template>

<script>
  import menuWrapper from "@/components/user/common/menu-wrapper.vue";
  import wxrecjarge from "@/views/user/recharge/wxrecharge.vue";

  export default {
    components: {
      menuWrapper,
      wxrecjarge,
    },
    data() {
      return {
        loading: true,
        PaymentType: 1,
        alipayHtml: "",
        PaymentTypes: [
          {
            Id: 1,
            Name: "支付宝",
            ImgPath: require("@/assets/imgs/pay/alipay.png"),
          },
          {
            Id: 2,
            Name: "微信",
            ImgPath: require("@/assets/imgs/pay/wechatpay.png"),
          },
        ],
        form: {
          Balance: 1,
          BalanceAgain: 1,
        },
        RechargeRecord: [],
        Pagination: {
          pageNumber: 1,
          totalSize: 0,
          pageSize: 5,
          pageSizes: [5, 20, 50, 100],
        },
        drErWeiMa: {
          url: "",
          id: "",
          qrcode: null,
        },
        UserBlance: 0,
        xvisible: false,
      };
    },
    created() {
      this.loading = false;
      this.getInfo();
      this.getRechargeRecord();
      
    },
    methods: {
      getInfo() {
        this.$axios
          .get("/api/User/GetUserInfo")
          .then((res) => {
            this.UserBlance = res.data.Balance;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },
      getRechargeRecord() {
        this.$axios
          .get(
            "/api/User/GetRecharges?PageSize=" +
              this.Pagination.pageSize +
              "&PageIndex=" +
              this.Pagination.pageNumber
          )
          .then((res) => {
            this.RechargeRecord = res.data.data;
            this.Pagination.totalSize = res.data.rowTotal;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      },
      onSubmit(e) {
        // debugger;
        if (this.form.Balance == 0) {
          this.$alert("充值云淘豆必须大于 0 ", "系统提示", {
            confirmButtonText: "确定",
              closeOnClickModal: true,
          });
          return;
        }
        if (this.form.Balance != this.form.BalanceAgain) {
          this.$alert("两次云淘豆不相同，请认真填写。", "系统提示", {
            confirmButtonText: "确定",
              closeOnClickModal: true,
          });
          return;
        }
        this.loading = true;
        //默认1支付宝，2微信
        this.alipayHtml = "";
        if (this.PaymentType == 1) {
          this.$axios
            .post("/api/payment/rechargebyalipay", this.form)
            .then((res) => {
              //this.alipayHtml = res.data;
              this.alipayHtml = res.data;
              this.$nextTick(() => {
                this.$refs.alipayWap.children[0].submit()
              })
            })
            .catch((error) => {
              this.loading = false;
            });
        }
        if (this.PaymentType == 2) {
          var payUrl = "/api/WechatPayment/QrCodePay";
          this.$axios
            .post(payUrl, this.form)
            .then((res) => {
              this.loading = false;
              if (
                res.data !== undefined &&
                res.data !== null &&
                res.data !== ""
              ) {
                this.drErWeiMa.url = res.data[0];
                this.drErWeiMa.id = res.data[1];
                //window.location.href = "/#/user/wxrecharge?url=" +res.data[0] +"&id=" +res.data[1];
                this.xvisible = true;
              } else {
                this.$alert("提交失败,刷新后重试。", "系统提示", {
                  confirmButtonText: "确定",
                    closeOnClickModal: true,
                });
                return;
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      },
      handleSizeChange(val) {
        this.Pagination.pageSize = val;
        this.Pagination.pageNumber = 1;
        this.getRechargeRecord();
      },
      pageChange(e) {
        this.Pagination.pageNumber = e;
        this.getRechargeRecord();
      },
    },
  };
</script>
<style>
  .cardRate {
    color: #ff6701;
    font-size: 16px;
    font-weight: 600;
  }
  .cardline {
    margin-top: 5px;
    padding-left: 80px;
  }
  .title {
    font-size: 30px;
    color: #757575;
    line-height: 68px;
  }
  .recharge .el-radio__inner {
    position: relative;
    top: -26px;
    left: 10px;
  }
  .recharge .tips {
      display: block;
      margin: 0 auto;
      color: red;
  }
  .el-button--primary{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
}
.el-button--primary:hover{
  background-color:   var(--ThemeColor,#ff6701);
    border-color:   var(--ThemeColor,#ff6701);
  opacity: 0.8;
}
</style>