<template>
  <el-dialog :visible="xvisible" center @closed="closed" @close="closeDialog" :modal-append-to-body="false">
    <!-- 左侧导航栏 -->
    <div style="text-align: center">
      <div
        id="weChatQrcode"
        ref="weChatQrcode"
        style="margin: 0 auto; width: 230px; height: 230px"
        v-if="xvisible"
      ></div>
      <p style="font-weight: bold; margin-top: 2%; line-height: 30px">
        使用微信扫码支付
      </p>
    </div>
    <div style="text-align: center; line-height: 30px">
      <p style="font-weight: bold; line-height: 30px">
        请在 30 分钟内完成付款，以免订单被取消。
      </p>
      <p>付款完成后，我们会立即处理你的订单，并更新你账户的云淘豆</p>
      <br />
    </div>
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  components: {},
  props: {
    url: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    xvisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qrcodeDr: null,
      loading: true,
      number: 0,
      wxpayTimer: null,
    };
  },
  watch: {
    xvisible(e) {
      if (e) {
        this.load();
      }
    },
  },
  created() {
    // debugger;
    this.loading = false;
  },
  methods: {
    closed() {
      this.number = 0;
      clearInterval(this.wxpayTimer);
      this.qrcodeDr = null;
    },
    closeDialog(){
       this.$emit("update:xvisible", false);
    },
    load() {
      this.number = 0;
      this.$nextTick(function () {
        this.qrcodeDr = new QRCode(this.$refs.weChatQrcode, {
          width: 230,
          height: 230,
          text: this.url,
          correctLevel: QRCode.CorrectLevel.H,
        });
        clearInterval(this.wxpayTimer);
        this.wxpayTimer = setInterval(this.paystatus, 3000);
      });
    },
    paystatus() {
      this.number++;
      if (this.id.length > 0 && this.number <= 600) {
        this.$axios
          .get("/api/WechatPayment/QueryByOutTradeNo?outTradeNo=" + this.id)
          .then((res) => {
            if (res.data != "NOTPAY")
            {
              clearInterval(this.wxpayTimer);
              this.closeDialog()
              this.$alert("支付成功。", "系统提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                   this.$router.go(0)
                },
              })
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      if (this.number > 600) {
        clearInterval(this.wxpayTimer);
        this.$axios
          .get("/api/WechatPayment/OutTradeNoClose?outTradeNo=" + this.id)
          .then((res) => {
            this.closeDialog()
            this.$alert("订单超时，已关闭", "系统提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.$router.go(0)
              },
            });
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      
    },
    mounted() {},
    beforeDestroy() {
      clearInterval(this.wxpayTimer);
    },
  },
};
</script>
<style>
.cardRate {
  color: red;
  font-size: 16px;
  font-weight: 600;
}
.cardline {
  margin-top: 5px;
  padding-left: 80px;
}
.title {
  font-size: 30px;
  color: #757575;
  line-height: 68px;
}
</style>